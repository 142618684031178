$(function () {

    (function ($) {
        $.fn.scrollHeight = function () {
            scrollHeight = 0
            $(window).scroll(function () {
                if ($(this).scrollTop() > 0) {
                    scrollHeight = $(this).scrollTop();
                } else {
                    scrollHeight = 0;
                }
            });
        }
    })(jQuery);
    $(this).scrollHeight();

    (function () {
        $('.js-toTop').on('click', function () {
            var position = $('body,html').offset().top;
            animate(position);
        });

        function animate(position) {
            $('body,html').animate({
                scrollTop: position
            }, 500, 'swing');
            return false;
        };
    })();

    // メニューのアコーディオン
    (function menuAccordion() {
        $('.js-menuAccordion').on('click', function () {
            if ($(this).children('div').hasClass('disable')) {
                return;
            }
            if ($(this).children('.js-ac-in-ac-target').is(':hidden')) {
                $(this).addClass('is-active').children('.js-ac-in-ac-target').slideDown('fast');
            } else {
                $(this).removeClass('is-active').children('.js-ac-in-ac-target').slideUp('fast');
            }
        });
    })();

    // gnav
    (function () {
        var element = {
            navTrig: $('.js-toMenu'),
            overLay: $('.js-overLay'),
            gNav: $('.js-nav'),
            closeNav: $('.js-navClose')
        };

        function handleTouchMove(event) {
            event.preventDefault();
        }

        function gnavOpen() {
            element.overLay.addClass('is-active');
            element.gNav.addClass('is-active');
            document.addEventListener('touchmove', handleTouchMove, {passive: false});
        }

        function gnavClose() {
            element.overLay.removeClass('is-active');
            element.gNav.removeClass('is-active');
            document.removeEventListener('touchmove', handleTouchMove, {passive: false});
        }

        element.navTrig.on('click', () => gnavOpen());
        element.closeNav.on('click', () => gnavClose());
        element.overLay.on('click', () => gnavClose());
    })();

});
